import { Action } from 'redux';

import { REQUEST_FAILURE, REQUEST_START } from 'store/requests.actions';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { AvailableLoadSearchType } from 'shared/enums/available-loads/search-type.enum';
import { SEARCH_AVAILABLE_LOADS_SUCCESS } from 'store/epics/find-loads/find-loads-base.actions';
import { UserPreferredLane } from 'shared/models/preferred-lane.model';
import { AvailableLoadSummaryResponse } from 'shared/models/loads/available-load-summary-response.model';
import { LoadSummaryCarrierOffer } from 'shared/models/loads/load-summaries/load-summary-carrier-offer';
import { CarrierBook } from 'shared/models/loads/load-books/carrier-book.model';

export { SEARCH_AVAILABLE_LOADS_SUCCESS, PROCESS_AVAILABLE_LOADS_SEARCH_PARAMS } from 'store/epics/find-loads/find-loads-base.actions';
export { REFRESH_AVAILABLE_LOADS, refreshAvailableLoads, processAvailableLoadsSearchParams } from 'store/epics/find-loads/find-loads-base.actions';
export { pushAvailableLoadsSearchURL, pushAvailableLoadsSearchLoadNumberURL, PUSH_AVAILABLE_LOAD_SEARCH_URL } from 'store/epics/find-loads/find-loads-base.actions';

export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';
export const UNSET_SEARCH_CRITERIA = 'UNSET_SEARCH_CRITERIA';

export const setSearchCriteria = (criteria: AvailableLoadSearchCriteriaJSON) => ({type: SET_SEARCH_CRITERIA, criteria});
export const unsetSearchCriteria = () => ({type: UNSET_SEARCH_CRITERIA});

export const SORT = 'SORT';

export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';

export const SEARCH_MODIFIED = 'SEARCH_MODIFIED';
export const searchModified = () => ({type: SEARCH_MODIFIED});

export const SET_SEARCH_CORRELATION_ID = 'SET_SEARCH_CORRELATION_ID';
export const setSearchCorrelationId = (searchCorrelationId: string) =>({type: SET_SEARCH_CORRELATION_ID, searchCorrelationId});

export const RESET_AVAILABLE_LOADS = 'RESET_AVAILABLE_LOADS';
export const SEARCH_AVAILABLE_LOADS = `${REQUEST_START}:SEARCH_AVAILABLE_LOADS`;
export const SEARCH_AVAILABLE_LOADS_FAILURE = `${REQUEST_FAILURE}:SEARCH_AVAILABLE_LOADS_FAILURE`;
export const UPDATE_OFFER_STATUS = 'UPDATE_OFFER_STATUS';
export const ACCEPT_COUNTERED_OFFER = 'ACCEPT_COUNTERED_OFFER';
export const STORE_NEW_OFFER = 'STORE_NEW_OFFER';
export const STORE_RELOAD_OFFERS = 'STORE_RELOAD_OFFERS';
export const STORE_NEW_CARRIER_BOOK = 'STORE_NEW_CARRIER_BOOK';
export const STORE_NEW_FAILED_BOOK = 'STORE_NEW_FAILED_BOOK';
export const CLEAR_FAILED_BOOK = 'CLEAR_FAILED_BOOK';

export interface SearchAvailableLoadsAction extends Action {
  criteria: AvailableLoadSearchCriteriaJSON;
}

export const MICROSOFT_LOAD_SUCCESS = 'MICROSOFT_LOAD_SUCCESS';
export const FETCH_MICROSOFT_SCRIPT = 'FETCH_MICROSOFT_SCRIPT';

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const resetErrorMessage = () => ({type: RESET_ERROR_MESSAGE});

export const sort = () => ({type: SORT});

export const setSearchType = (value: AvailableLoadSearchType) => ({type: SET_SEARCH_TYPE, value});

export const resetAvailableLoads = () => ({type: RESET_AVAILABLE_LOADS});
export const searchAvailableLoads = (criteria: AvailableLoadSearchCriteriaJSON): SearchAvailableLoadsAction => ({type: SEARCH_AVAILABLE_LOADS, criteria});
export const searchAvailableLoadsSucceeded = (payload: AvailableLoadSummaryResponse) => ({type: SEARCH_AVAILABLE_LOADS_SUCCESS, payload});
export const searchAvailableLoadsFailed = (payload: APIErrorResponse) => ({type: SEARCH_AVAILABLE_LOADS_FAILURE, payload});
export const updateOfferStatus = (parameter: OfferStatusUpdateParameter) => ({type: UPDATE_OFFER_STATUS, payload: parameter});
export const acceptCounteredOffer = (loadNumber: number) => ({type: ACCEPT_COUNTERED_OFFER, payload: loadNumber});

export const storeNewOffer = (carrierOffer: LoadSummaryCarrierOffer) => ({type: STORE_NEW_OFFER, payload: carrierOffer});
export const storeReloadOffers = (reloadOffers: LoadSummaryCarrierOffer[]) => ({type: STORE_RELOAD_OFFERS, payload: reloadOffers});

export const storeNewCarrierBook = (carrierBook: CarrierBook) => ({type: STORE_NEW_CARRIER_BOOK, payload: carrierBook});
export const storeNewFailedBook = (loadNumber: number) => ({type: STORE_NEW_FAILED_BOOK, payload: loadNumber});
export const clearFailedBook = () => ({type: CLEAR_FAILED_BOOK});

export interface OfferStatusUpdateParameter {
  loadNumber: number;
  resultReceivedDate?: Date;
  price?: number;
  offerId?: number;
  currencyCode?: string;
  updatedStatus: string;
  offerRequestId?: string;
  isFinalNegotiation?: boolean;
  rejectionReason?: string;
}

export const microsoftLoadSuccess = (isMicrosoftLoaded: boolean) => ({type: MICROSOFT_LOAD_SUCCESS, isMicrosoftLoaded});
export const fetchMicrosoftScript = () => ({type: FETCH_MICROSOFT_SCRIPT});

export interface CriteriaAction extends Action {
  criteria: AvailableLoadSearchCriteriaJSON;
}

export const APPLY_FIND_LOADS_SEARCH = 'APPLY_FIND_LOADS_SEARCH';
export const CLEAR_FIND_LOADS_SEARCH = 'CLEAR_FIND_LOADS_SEARCH';

export const applySearch = (criteria: AvailableLoadSearchCriteriaJSON, searchType: AvailableLoadSearchType) => ({
  type: APPLY_FIND_LOADS_SEARCH,
  criteria,
  searchType
});
export const clearSearch = (searchType: AvailableLoadSearchType) => ({type: CLEAR_FIND_LOADS_SEARCH, searchType});

export const PROCESS_INCOMING_CRITERIA = 'PROCESS_INCOMING_CRITERIA';

export const processIncomingCriteria = (criteria: AvailableLoadSearchCriteriaJSON): CriteriaAction => ({type: PROCESS_INCOMING_CRITERIA, criteria});

export const ADD_PREFERRED_LANE_SUCCESS = 'ADD_PREFERRED_LANE_SUCCESS';
export const ADD_PREFERRED_LANE_ERROR = 'ADD_PREFERRED_LANE_ERROR';

export const addPreferredLaneSuccess = (lane: UserPreferredLane) => ({type: ADD_PREFERRED_LANE_SUCCESS, lane});
export const addPreferredLaneError = (error: APIErrorResponse) => ({type: ADD_PREFERRED_LANE_ERROR, error});

export const GET_SUGGESTED_LOADS = 'GET_SUGGESTED_LOADS';
export const getSuggestedLoads = () => ({type: GET_SUGGESTED_LOADS});
