import moment from 'moment';
import { Cookie } from 'ng2-cookies';
import { Container, Service } from 'typedi';
import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { AppRoute, StartPageRedirects, StartPageSort } from 'app/routesEnum';
import { Grants } from 'features/security/grants';
import { User } from 'shared/models/user.model';
import { Http, Headers, API_XDATE_FORMAT } from 'app/globals/constants';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { environment } from 'environments/environment';
import { BaseRepository } from "@app/repositories/base.repository";
import { LocalStorageRepository } from "@app/repositories/local-storage.repository";
import { LDClient } from 'launchdarkly-js-client-sdk';

// for tree-shaking.  These imports are not using default exports.  TS doesn't support fully
const HmacSHA512 = require('crypto-js/hmac-sha512');
const Base64 = require('crypto-js/enc-base64');

const isDev = window.location.host === 'localhost:3000' || window.location.host === 'dev.navispherecarrier.com';


@Service()
export class AuthRepository extends BaseRepository {

  protected baseUrl: string;

  constructor(url?: string) {
    super();

    this.baseUrl = url || apiConfig.userAPI;
  }

  private sessionStorage: LocalStorageRepository = new LocalStorageRepository();

  public loginWithCredentials(username, password): Observable<UserJSON> {
    const url = `${this.baseUrl}/User/${encodeURIComponent(username.trim())}/LoginUser`;

    const headers = this.generateRequestHeaders({
      url,
      xDate: this.generateXDate(),
      key: environment.apiSecret,
      method: Http.POST,
      contentType: 'application/json',
      
    });

    // add X-Redirect-From-Mobile header to login call if the mobile query parameter is present
    const isMobileRedirect = new URLSearchParams(window.location.search).get('mobile');
    isMobileRedirect && (headers['X-Redirect-From-Mobile'] = 'true');

    const body = JSON.stringify(password.trim());

    return ajax
      .post(url, body, headers)
      .map(res => {
        if (isMobileRedirect) {
          return res.response;
        } else {
          return this.verificateLimitedUser(res);
        }
      })
      .catch(err => {
        const errResponse = err.response ? err.response : { type: err?.type, errors: err?.errors };
        return Observable.throwError(new APIErrorResponse(errResponse, err.status));
      });
  }

  public loginWithOTP(token): Observable<any> {
    const url = `${this.baseUrl}/User/validateOneTimePassword`;
    const headers = this.generateRequestHeaders({
      url,
      xDate: this.generateXDate(),
      key: environment.apiSecret,
      method: Http.POST,
      contentType: 'application/json',
    });

    const body = JSON.stringify(token.trim());

    return ajax.post(url, body, headers)
		.map(res => {
			console.log(res);
        return this.verificateLimitedUser(res);
      })
      .catch(err => {
        const errResponse = err.response ? err.response : { type: err?.type, errors: err?.errors };
        return Observable.throwError(new APIErrorResponse(errResponse, err.status));
      });
  }

  public loginFromCookie(): Observable<UserJSON> {
    const url = `${this.baseUrl}/user`;
    const cookie = Cookie.get(Container.get('remoteAuthToken'));
    const [username, hashedPassword] = cookie.split('|');

    const headers = this.generateRequestHeaders({
      username,
      url,
      xDate: this.generateXDate(),
      key: hashedPassword,
      method: Http.GET,
      contentType: 'application/x-www-form-urlencoded'
    });

    return ajax.get(url, headers).map(res => res.response);
  }

  public generateXDate = () => {
    return moment()
      .locale('en')
      .format(API_XDATE_FORMAT);
  };

  public generateRequestHeaders(
    config: AuthRequestHeadersConfig
  ): AuthRequestHeaders {
    const { key, username, xDate, contentType, accept } = config;
    if (key == null) {
      throw new Error('ApiKey is required to generate requests');
    }

    const signature = this.assembleSignature(config);
    const encodedHash = this.generateHash(signature, key);

    const xBearerHeader = config?.xBearerAuthorization ? {
      [Headers.X_BEARER_AUTHORIZATION]: `Bearer ${config.xBearerAuthorization}`
    } : {};

    let response: AuthRequestHeaders = {
      [Headers.X_DATE]: xDate,
      [Headers.AUTHORIZATION]: `${username ? `${username}:${encodedHash}` : encodedHash
        }`,
      [Headers.API_KEY]: environment.apiKey,
      [Headers.ACCEPT]: accept ? accept : 'application/json',
      ...xBearerHeader
    };

    if (contentType != null) {
      // adding a null value to HttpHeaders breaks it, so only add it if it is not null
      response = {
        ...response,
        [Headers.CONTENT_TYPE]: contentType,
      };
    }
    return response;
  }

  public assembleSignature = ({ method, contentType, url, xDate }) => {
    let signatureArray = [method?.toUpperCase()];

    if (contentType != null) {
      // Need to NOT include content type in our hash when we're uploading files.
      signatureArray = [...signatureArray, contentType];
    }

    signatureArray = [
      ...signatureArray,
      url.includes('navispherecarrier.com') ? `/${url.split('?')[0].split('/').slice(4, url.length).join('/')}` : url.replace(/^https?:\/\/[^\/]*([^?]*)(.*)?/g, '$1'),
      xDate
    ];
    return signatureArray.join('\n');
  };

  public generateHash = (signature, key) => {
    const hash = HmacSHA512(signature, key);
    const hashInBase64 = Base64.stringify(hash);

    return hashInBase64.replace(/\+/g, encodeURIComponent('+'));
  };

  public hasRemoteLoginCookie = () => {
    const cookieDetails = Cookie.get(Container.get('remoteAuthToken'));
    return cookieDetails != null && cookieDetails !== '';
  };

  public clearRemoteLogin = () => {
    Cookie.delete(Container.get('remoteAuthToken'));
    Cookie.delete(Container.get('remoteAuthToken'), '/');
    let cookieDomain, cookiePath;
    if (window.location.hostname.includes('.navispherecarrier.com')) {
      cookieDomain = '.navispherecarrier.com';
      cookiePath = '/';
    }
    Cookie.delete(Container.get('remoteAuthToken'), cookiePath, cookieDomain);
  };

  public storeUserInSession = (userJSON: UserJSON) => {
    this.sessionStorage.set('user', JSON.stringify(userJSON));
    this.sessionStorage.set('user_token', userJSON.hashedPassword);
  };

  public getUserStartPage = (user: User): string => {
    let startPageRoute = '/home';
    if (user?.isAuthenticated) {
      const key = user.getStartPageKey();
      if (key) {
        if (StartPageRedirects.hasOwnProperty(key)) {
          startPageRoute = StartPageRedirects[key];
        }
      }
    }
    return startPageRoute;
  };

  public getBestPotentialUserStartPageRoute = (user: User, carrierRes): { pathname: string, state?: any } => {
    // available start pages THIS user has access to.
    const availableStartPagesStrings = this.getPotentialUserStartPages(user);
    // This user's preferred start page.
    const userStartPage = this.getUserStartPage(user);
    // all available start pages for any user
    const generatedAvailableStartPages = this.generateAvailableMenuItems();
    if (availableStartPagesStrings.length > 0) {
      // filter the list of ALL start pages by what I have access to.
      const availableStartPages = generatedAvailableStartPages.filter(
        menuItem => availableStartPagesStrings.includes(menuItem.menuKey)
      );
      if (availableStartPages.length > 0) {
        // find this user's preferred start page in the list of available start pages.
        const startPageIndex = availableStartPages.findIndex(page => page.path === userStartPage);
        if (startPageIndex !== -1) {
          const startPageItem = availableStartPages[startPageIndex];
          if (startPageItem) {
            // if we found that user's preferred start page in the list of available start pages, then go there.
            return { pathname: startPageItem.path };
          }
        }
        // go to the first item that the user has access to.
        return { pathname: availableStartPages[0].path };
      }
    }
    return { pathname: '/settings' }; // For some reason this user can't see ANYTHING, so just send them to their settings
  };

  public generateAvailableMenuItems = (): MenuItem[] => {
    const menuItems = [];
    for (const page in StartPageRedirects) {
      if (StartPageRedirects[page]) {
        const menuItem: MenuItem = {
          menuKey: page as ResourceKey,
          menuLevel: StartPageSort[page],
          // if the value is not in our startPage list don't add it
          path: StartPageRedirects[page],
        };
        menuItems.push(menuItem);
      }
    }
    menuItems.sort((a, b) => a.menuLevel - b.menuLevel);
    return menuItems;
  };

  public getPotentialUserStartPages = (user: User): ResourceKey[] => {
    if (user?.isAuthenticated) {
      const roles = user.roles;
      if (roles) {
        return Object.keys(StartPageRedirects).reduce(
          (keys: ResourceKey[], key: ResourceKey) => {
            const grantFunc = this.resolveStartPageGrantFunction(key);
            if (grantFunc?.(user)) {
              return [...keys, key];
            }
            return keys;
          },
          []
        );
      }
    }

    return [] as ResourceKey[];
  };

  public getLoginRedirect = (
    user: User,
    routerState: unknown
  ): string => {
    const sessionRedirect = this.sessionStorage.get('redirect');

    if ((routerState as any)?.from) {
      return (routerState as any).from.pathname;
    }

    if (sessionRedirect) {
      this.sessionStorage.remove('redirect');
      return sessionRedirect;
    }

    return this.getUserStartPage(user);
  };

  public loadUserFromSession = (): UserJSON => {
    const json = this.sessionStorage.get('user');
    return json && JSON.parse(json);
  };

  public loadUserTokenFromSession = (): string => {
    return this.sessionStorage.get('user_token');
  };

  public clearUserFromSession = () => {
    this.sessionStorage.remove('user');
    this.sessionStorage.remove('user_token');
  };

  // Forcing API error for limited users, to be removed by later development to move to the API side. #886706, #906560
  private verificateLimitedUser = (res: any) => {
    const flag = Container.get<LDClient>('LD_CLIENT').allFlags().showDisableLimitedUserLoginMessage

    if (res?.response?.userType === 0 && res?.response?.carrierCode === 'TMPCARRIER' && flag) {
      throw new APIErrorResponse(
        {
          type: 2,
          errors: [
            {
              message: res?.response?.username as string | '',
              code: {
                code: 'limitedUser',
                description: '',
                apiName: 5,
                category: 2,
                shortCode: '',
              },
            },
          ],
        },
        401
      );
    } else {
      return res.response;
    }
  }

  private resolveStartPageGrantFunction(startPageKey: string) {
    switch (startPageKey) {
      case 'ADMINISTER_USERS':
        return Grants.AdminGrant;
      case 'POST_TRUCKS':
        return Grants.PostTrucksGrant;
      case 'MY_LOADS':
        return Grants.MyLoadsGrant;
      case 'ACCOUNT_RECEIVABLE':
        return Grants.AccountsReceivableGrant;
      case 'PAYMENTS':
        return Grants.PaymentsGrant;
      case 'FIND_LOADS':
        return Grants.FindLoadsGrant;
      case 'TMC_TENDERS':
      case 'CHRW_TENDERS':
        return Grants.TendersGrant;
      case 'IMPERSONATE':
        return Grants.ImpersonateAllGrant;
      case 'DOCK_MGR':
        return Grants.DockMgrGrant;
      case 'RATES':
        return Grants.RatesGrant;
      case 'TMC_REPORTS':
        return Grants.TMCReportsGrant;
      case 'SPOT_BIDS':
        return Grants.SpotBidsGrant;
      case 'MANAGE_INVOICES':
        return Grants.ManageInvoicesGrant;
      case 'PREFERRED_LANES':
        return Grants.PreferredLanesGrant;
      case 'MY_SHIPMENTS':
        return Grants.MyShipmentsGrant;
      case 'FREIGHT_ALERTS':
      case 'CARRIER_TASKS':
      case 'EMAIL_PROFILE':
      // not used anymore (should not set users to this in the future)
    }
    return null;
  }
}

interface MenuItem {
  menuKey: ResourceKey;
  menuLevel: number;
  path: string;
}
