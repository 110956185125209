import { API_XDATE_FORMAT, Headers, Http } from 'app/globals/constants';
import moment from 'moment';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  RawAxiosRequestHeaders
} from "axios";

import { store, AuthState } from 'store';
import { HmacSHA512 } from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import { environment } from 'environments/environment';
import { User } from "shared/models/user.model";
import { logout } from "@features/security/auth.actions";

export const resolveApi = (baseURL: string, config?: ApiConfig): AxiosInstance => {
  const _this = axios.create({ baseURL });
  _this.interceptors.request.use((config: AxiosRequestConfig, apiConfig?: ApiConfig) => {
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage'));
    if (!accessToken) {
      //HMAC
      const hmac: User = store.getState().auth?.user;

      const hash = generateHash(
        assembleSignature({
          method: config.method,
          contentType: config.responseType,
          url: config.url,
          xDate: generateXDate(),
        }),
        hmac?.token || environment.apiKey
      );
      const legacyToken = `${hmac.username ? `${hmac.username}:${hash}` : hash}`;
      config.headers[Headers.X_DATE] = generateXDate();
      config.headers[Headers.AUTHORIZATION] = legacyToken;
    } else {
      //JWT
      const bearerToken = `Bearer ${accessToken?.accessToken}`;
      config.headers[Headers.AUTHORIZATION] = bearerToken;
    }

    // Common
    config.headers[Headers.API_KEY] = environment.apiKey;
    return config;
  });
  return _this;
};

const api = resolveApi(process.env.NavisphereCarrierUserURL);
export interface ApiConfig {
  method: string;
  contentType: string;
  url: string;
  xDate: string;
  key?: string;
}
export const generateXDate = () => {
  return moment().locale('en').format(API_XDATE_FORMAT);
};

export const generateRequestHeaders = (config: AuthRequestHeadersConfig): AuthRequestHeaders => {
  const tokens = JSON.parse(localStorage.getItem('okta-token-storage'));
  const bearer = tokens?.accessToken?.accessToken.replace(/[\r\n]+/gm, '') || null;
  const { key, username, xDate, contentType, accept } = config;

  let authHeaders = {};
  if (bearer) {
    const authorization = `Bearer ${bearer}`;
    authHeaders = {
      [Headers.AUTHORIZATION]: authorization,
    };
  } else {
    let _key = key || localStorage.getItem('_key_');
    if (_key == null) {
      throw new Error('ApiKey is required to generate requests');
    }
    const signature = assembleSignature(config);
    const encodedHash = generateHash(signature, _key);
    authHeaders = {
      [Headers.X_DATE]: xDate,
      [Headers.AUTHORIZATION]: `${username ? `${username}:${encodedHash}` : encodedHash}`,
    };
  }

  let response: AuthRequestHeaders = {
    ...authHeaders,
    [Headers.API_KEY]: environment.apiKey,
    [Headers.ACCEPT]: accept ? accept : 'application/json',
  }

  if (contentType != null) {
    // adding a null value to HttpHeaders breaks it, so only add it if it is not null
    response = {
      ...response,
      [Headers.CONTENT_TYPE]: contentType,
    };
  }

  return response;
};

const options: CreateAxiosDefaults = {
  baseURL: process.env.NavisphereCarrierUserURL,
};

export function catchAuthorizationErrors(error: AxiosError): AxiosError<any> {

  if (error.status === 401) {
    store.dispatch(logout());
  }

  throw new AxiosError(error.message, error.code);
}


export const updateRequestHeaders = (method: string, url: string, headers?: AuthRequestHeaders): AuthRequestHeaders => {
  if (!headers) {
    headers = {
      ...generateHeaders(method, url, 'application/json'),
    };
  } else {
    let contentType = headers[Headers.CONTENT_TYPE] || 'application/json';
    const accept = headers[Headers.ACCEPT];

    if (contentType === 'multipart/form-data') {
      contentType = null;
    }

    const newHeaders = generateHeaders(method, url, contentType, accept);

    headers = { ...headers, ...newHeaders };
  }
  return headers;
};

export const postWithMultipart = (path: string, body: any, headers?: AuthRequestHeaders): Promise<any> => {
  const url = `${options.baseURL}${path}`;
  headers = updateRequestHeaders(Http.POST, url, headers);

  // MUST allow the browser to set the content-type header so that the form boundaries can be pulled in.
  delete headers[Headers.CONTENT_TYPE];

  let config: AxiosRequestConfig = {
    headers: headers as RawAxiosRequestHeaders,
  };

  return api
    .post(url, body, config)
    .then((resp: any) => resp?.data)
    .catch(err => catchAuthorizationErrors(err));
};

export const getBlob = (path: string, headers?: AuthRequestHeaders): Promise<any> => {
  const url = `${options.baseURL}${path}`;
  headers = updateRequestHeaders(Http.GET, url, headers);

  let config: AxiosRequestConfig = {
    headers: headers as RawAxiosRequestHeaders,
    responseType: 'blob',
  };
  return api
    .get(url, config)
    .then((ajaxResponse: any) => ajaxResponse?.data)
    .catch(err => catchAuthorizationErrors(err));
};

export const pickBy = (object, predicate = v => v) => {
  const obj = {};
  for (const [key, value] of Object.entries(object)) {
    if (predicate(value)) obj[key] = value;
  }
  return obj;
};

export const generateHeaders = (method: string, url: string, contentType: string, accept?: string) => {
  const authState = store.getState().auth;
  if (authState.isAuthenticated && authState.user.isAuthenticated) {
    return getAuthenticatedRequestHeaders(method, url, contentType, authState, accept);
  }
  // Uses generic auth headers, without user data.
  return generateRequestHeaders({
    url,
    method,
    accept,
    contentType,
    key: environment.apiKey,
    xDate: generateXDate(),
    username: '',
  });
};

export const getAuthenticatedRequestHeaders = (
  method: string,
  url: string,
  contentType: string,
  authState: AuthState,
  accept?: string
): AuthRequestHeaders => {
  const user = authState.user;
  if (!authState.isAuthenticated) {
    throw new Error('User must be authenticated to generate authenticated request headers.');
  }
  return generateRequestHeaders({
    url,
    method,
    contentType,
    xDate: generateXDate(),
    key: authState.token,
    username: user.username,
    accept,
  });
};


export const assembleSignature = ({ method, contentType, url, xDate }) => {
  let signatureArray = [];

  if (method) signatureArray.push(method.toUpperCase());
  if (contentType) signatureArray.push(contentType);

  let processedUrl = url.replace(/^https?:\/\/[^\/]*([^?]*)(.*)?/g, '$1');
  signatureArray.push(processedUrl);

  if (xDate) signatureArray.push(xDate);

  return signatureArray.join('\n');
};

export const generateHash = (signature, key) => {
  const hash = HmacSHA512(signature, key);
  const hashInBase64 = Base64.stringify(hash);

  return hashInBase64.replace(/\+/g, encodeURIComponent('+'));
};

