import { Inject, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { AccessTokenType } from 'shared/enums/access-token-type.enum';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { User } from 'shared/models/user.model';
import { UserPreferredLane } from 'shared/models/preferred-lane.model';
import { LegalAgreementType } from 'shared/enums/legal-agreement/legal-agreement.enum';
import LegalAgreementStub from 'shared/models/settings/legal-agreement-stub.model';

@Service()
export class UserRepository extends CacheableRepository {

  @Inject('apiConfig.userAPI')
  protected baseUrl;

  validateUsername(username: string): Observable<RegistrationValidationJSON> {
    return this.get(`/Registrations/Validation/Username/${username.trim()}`);
  }

  validatePassword(password: string): Observable<RegistrationValidationJSON> {
    return this.get(`/Registrations/Validation/Password/${password.trim()}`);
  }

  validateCarrierCode(carrierCode: string): Observable<RegistrationValidationJSON> {
    return this.get(`/Registrations/Validation/Carrier/${carrierCode.trim()}`);
  }

  validateEmail(carrierCode: string, email: string): Observable<RegistrationValidationJSON> {
    return this.get(`/Registrations/Validation/Carrier/${carrierCode.trim()}/EmailAddress/${email.trim()}`);
  }

  validateCarrierContactName(carrierCode: string, firstName: string, lastName: string): Observable<RegistrationValidationJSON> {
    return this.get(`/Registrations/Validation/Carrier/${carrierCode.trim()}/ContactName/${firstName.trim()}/${lastName.trim()}`);
  }

  register(params: RegistrationRequest) {
    return this.post(`/Registration`, params);
  }

  forgotUsername(request: { emailAddress: string, carrierCode: string }): Observable<null> {
    return this.post(`/User/Username/Requests`, request);
  }

  forgotPassword(userName: string): Observable<null> {
    return this.post(`/Users/${userName.trim()}/AccessTokens`, { type: AccessTokenType.ResetPassword });
  }

  resetPassword(token: string, password: string) {
    return this.patch(`/Users/AccessToken/${token.trim()}`, { password });
  }

  updateUserProperties(params: UserPatchRequest): Observable<UserJSON> {
    return this.patch(`/User/${params.userId}`, params)
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  acceptTermsAndConditions(userJSON: UserJSON): Observable<UserJSON> {
    const params = { userId: userJSON.userID, acceptedAgreement: true };
    return this.patchForUser(`/User/${userJSON.userID}`, params, userJSON.username, userJSON.hashedPassword)
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  refreshUserInSession(username: string, hashedPassword: string): Observable<UserJSON> {
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage'));
    if (accessToken) {
      return this.get(`/User/${username}`);
    }
    return this.getForUser(`/User/${username}`, username, hashedPassword);
  }

  getSignedToken(username: string): Observable<SignedTokenResponse> {
    return this.get(`/User/${username}/jwt`);
  }

  validateAccessToken(token: string): Observable<AccessTokenValidationResponse> {
    return this.get(`/AccessTokens/${token}`)
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  registerWithToken(userId, accessToken, request) {
    return this.patch(`/AccessTokens/${accessToken}/Users/${userId}`, { userId, ...request });
  }

  getPilotSettings(user: User): Observable<PilotSettingsRequest[]> {
    return this.get(`/User/${user.userId}/PilotSettings`);
  }

  updatePilotSetting(user: User, setting: PilotSettingsRequest): Observable<PilotSettingsRequest[]> {
    return this.put(`/User/${user.userId}/PilotSettings/${setting.pilotSettingId}`, setting);
  }

  createPilotSetting(user: User, setting: PilotSettingsRequest): Observable<PilotSettingsRequest[]> {
    return this.post(`/User/${user.userId}/PilotSettings/`, setting);
  }

  getPreferredLanes(user: User): Observable<UserPreferredLane[]> {
    return this.get(`/User/${user.userId}/PreferredLanes`)
      .map(results => results.map(json => new UserPreferredLane(json)));
  }

  addPreferredLane(lane: UserPreferredLane): Observable<[UserPreferredLane, boolean]> {
    return this.post(`/User/${lane.userId}/PreferredLanes`, lane.toJson())
      .map(json => [new UserPreferredLane(json), true] as [UserPreferredLane, boolean])
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  editPreferredLane(lane: UserPreferredLane): Observable<[UserPreferredLane, boolean]> {
    return this.put(`/User/${lane.userId}/PreferredLanes/${lane.userPreferredLaneId}`, lane.toJson())
      .map(json => [new UserPreferredLane(json), false] as [UserPreferredLane, boolean])
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  deletePreferredLane(lane: UserPreferredLane): Observable<[UserPreferredLane, boolean]> {
    return this.delete(`/User/${lane.userId}/PreferredLanes/${lane.userPreferredLaneId}`)
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  addLegalAgreement(username: string, legalAgreement: LegalAgreementStub) {
    return this.post(`/Users/${username}/LegalAgreements`, legalAgreement)
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  getLegalAgreements(username: string, legalAgreementType?: LegalAgreementType): Observable<LegalAgreementStub[]> {
    let url = `/Users/${username}/LegalAgreements`;
    if (!!legalAgreementType) {
      url = `${url}?legalAgreementTypeId=${legalAgreementType}`;
    }

    const legalAgreementStubs = this.get(url)
      ?.map(response => response?.map(legalAgreement => {
        return !!legalAgreement
          ? { ...legalAgreement } as LegalAgreementStub
          : null;
      }));

    return legalAgreementStubs;
  }

  updatePreferredOktaEmailAddress(preferredEmailAddress: string) {
    return this.post(`/User/updatePreferredOktaEmailAddress/`, { preferredEmailAddress })
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  getOktaPreferredEmail(user: User) {
    return this.get(`/User/getUserPreferredEmailInfo`)
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  resendEmailAddressVerificationEmail() {
    return this.post('/User/resendEmailAddressVerificationEmail', {})
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  getUser() {
    return this.getPromise('/User')
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }

  loginWithOktaUser() {
    return this.postPromise('/User/LoginOktaUser', {})
      .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)));
  }
}
