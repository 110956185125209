import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Headers } from "app/globals/constants";
import { environment } from 'environments/environment';
import { store } from 'store';
import { assembleSignature, generateHash, generateXDate } from 'api/utils';
import { User } from 'shared/models/user.model';

export const getApi = (baseURL: string, needsAuthentication: boolean = true): AxiosInstance => {
  const _this = axios.create({ baseURL });

  //#region Register interceptors
  if (needsAuthentication) _this.interceptors.request.use(c => authMiddleware(c));
  //#endregion

  return _this;
};

const axiosResponseTypeMap = (config: AxiosRequestConfig) => {
  switch (config.responseType) {
    case 'json':
      return 'application/json';
 
    default:
      return config.responseType;
  }
}

// array of api middleware for requests
// const requestInterceptors = (config: AxiosRequestConfig) => ({authMiddleware(config: any)});
const authMiddleware = (config: AxiosRequestConfig, apiConfig?: ApiConfig) => {
  const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage'));
  const hmac: User = store.getState().auth?.user;
  const okta: OktaState = store.getState().okta;

  if (!accessToken) {
      // HMAC authorization gen.
    const xDate = generateXDate()
    const hash = generateHash(
      assembleSignature({
        method: config.method,
        contentType: axiosResponseTypeMap(config),
        url: config.url,
        xDate: xDate,
      }),
      hmac?.token || environment.apiKey
    );

    config.headers[Headers.X_DATE] = xDate;
    const legacyToken = `${hmac.username ? `${hmac.username}:${hash}` : hash}`;
    config.headers[Headers.AUTHORIZATION] = legacyToken;
  } else {
    const bearerToken = `Bearer ${accessToken?.accessToken}`;
    config.headers[Headers.AUTHORIZATION] = bearerToken;
  }

  // Common
  config.headers[Headers.API_KEY] = environment.apiKey;
  return config;
};

export interface ApiConfig {
  method: string;
  contentType: string;
  url: string;
  xDate: string;
  key?: string;
}

