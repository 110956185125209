import { LoadSummary } from 'features/my-loads-v2/types';

export const updateItemInLoadsList = (listToUpdate: LoadSummary[], updatedItem: any, indexToUpdate: number): LoadSummary[] => {
    if (indexToUpdate !== -1) {
        listToUpdate = listToUpdate.map((item, index) => {
            if (index === indexToUpdate) {
                return {
                    ...item,
                    ...updatedItem,
                };
            }
            return item;
        });
    }
    return listToUpdate;
}

export const getIndexToUpdate = (listToUpdate: LoadSummary[], shipmentId: any, bookId: any): number => {
    return (bookId || bookId == 0) ?
        listToUpdate.findIndex(item => item.loadNumber == shipmentId && item.bookId == bookId) :
        listToUpdate.findIndex(item => item.loadNumber == shipmentId);
}

export const getUpdatedLoadListResult = (listToUpdate: LoadSummary[], state: any) => {
    return {
        ...state,
        loadsList: listToUpdate
    };
}