import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/share';

import { Http } from '@app/globals/constants';
import { BaseRepository } from '@app/repositories/base.repository';

export class CacheableRepository extends BaseRepository {
  static cachedResponses: { [key: string]: any } = {};
  static cachedRequests: { [key: string]: Observable<any> } = {};

  /* CACHING */

  static responseIsCached(url: string): boolean {
    return Boolean(this.cachedResponses[url]);
  }

  static loadResponseFromCache(url: string): Observable<any> {
    return Observable.of(this.cachedResponses[url]);
  }

  static cacheResponse(url: string, response): any {
    this.cachedResponses[url] = response;

    return response;
  }

  static requestIsCached(url: string): boolean {
    return Boolean(this.cachedRequests[url]);
  }

  static loadRequestFromCache(url: string): Observable<any> {
    return this.cachedRequests[url];
  }

  static cacheRequest(url: string, request: Observable<any>) {
    this.cachedRequests[url] = request;

    // Remove from the cache after the request completes.
    return request.finally(() => (this.cachedRequests[url] = null));
  }

  static clearCache() {
    this.cachedRequests = {};
    this.cachedResponses = {};
  }

  /* Request Methods */

  getWithCache(
    path: string,
    forceRefresh?: boolean,
    responseType?: string
  ): Observable<any | any[]> {
    const url = this.getUrl(path);

    const headers = this.updateRequestHeaders(Http.GET, url);

    if (!forceRefresh && CacheableRepository.responseIsCached(url)) {
      return CacheableRepository.loadResponseFromCache(url);
    }

    if (!forceRefresh && CacheableRepository.requestIsCached(url)) {
      return CacheableRepository.loadRequestFromCache(url);
    }

    const request = this.get(path, headers, responseType)
      .map(res => CacheableRepository.cacheResponse(url, res))
      .share();

    return CacheableRepository.cacheRequest(url, request);
  }
}
