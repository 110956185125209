import { Container } from 'typedi';
import { ajax } from 'rxjs/observable/dom/ajax';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { Observable } from 'rxjs/Observable';
import { AjaxError, AjaxRequest, AjaxResponse } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

import { logout } from '@features/security/auth.actions';
import { AuthState, Store } from 'store';
import { Headers, Http } from '@app/globals/constants';
import { generateQueryString } from 'providers/query-string.provider';
import { environment } from 'environments/environment';
import { generateRequestHeaders, generateXDate } from "api/utils";

export class BaseRepository {
  protected generateQueryString = generateQueryString;

  // @Inject causes circular references
  protected get store() {
    return Container.get(Store);
  }

  protected getUrl(path) {
    return this.baseUrl + path;
  }

  constructor(protected baseUrl = '') { }

  get(path: string, headers?: AuthRequestHeaders, responseType = 'json'): Observable<any> {
    const url = this.getUrl(path);
    headers = this.updateRequestHeaders(Http.GET, url, headers);

    return ajax({ method: Http.GET, url, responseType, headers })
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .catch(err => this.catchAuthorizationErrors(err));
  }

  getPromise(path: string, headers?: AuthRequestHeaders, responseType = 'json'): Promise<any> {
    const url = this.getUrl(path);
    headers = this.updateRequestHeaders(Http.GET, url, headers);

    return ajax({ method: Http.GET, url, responseType, headers })
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .toPromise()
      .catch(err => this.catchAuthorizationErrors(err));
  }

  put(path: string, body: any, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    headers = this.updateRequestHeaders(Http.PUT, url, headers);

    return ajax
      .put(url, body, headers)
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .catch(err => this.catchAuthorizationErrors(err));
  }

  patch(path: string, body: any, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    headers = this.updateRequestHeaders(Http.PATCH, url, headers);

    return ajax
      .patch(url, body, headers)
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .catch(err => this.catchAuthorizationErrors(err));
  }

  post(path: string, body: any, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    headers = this.updateRequestHeaders(Http.POST, url, headers);

    return ajax
      .post(url, body, headers)
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .catch(err => this.catchAuthorizationErrors(err));
  }

  postPromise(path: string, body: any, headers?: AuthRequestHeaders): Promise<any> {
    const url = this.getUrl(path);
    headers = this.updateRequestHeaders(Http.POST, url, headers);

    return ajax
      .post(url, body, headers)
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .toPromise()
      .catch(err => this.catchAuthorizationErrors(err));
  }

  postWithRequest(ajaxRequest: AjaxRequest): Observable<any> {
    ajaxRequest.url = this.getUrl(ajaxRequest?.url);

    ajaxRequest.headers = this.updateRequestHeaders(Http.POST, ajaxRequest.url, ajaxRequest.headers);

    return ajax(ajaxRequest)
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .catch(err => this.catchAuthorizationErrors(err));
  }

  postWithMultipart(path: string, body: any, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    headers = this.updateRequestHeaders(Http.POST, url, headers);

    // MUST allow the browser to set the content-type header so that the form boundaries can be pulled in.
    delete headers[Headers.CONTENT_TYPE];

    return ajax
      .post(url, body, headers)
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .catch(err => this.catchAuthorizationErrors(err));
  }

  getBlob(path: string, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    headers = this.updateRequestHeaders(Http.GET, url, headers);

    return ajax({ method: Http.GET, url, headers, responseType: 'blob' })
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .catch(err => this.catchAuthorizationErrors(err));
  }

  delete(path: string, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    headers = this.updateRequestHeaders(Http.DELETE, url, headers);

    return ajax
      .delete(url, headers)
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .catch(err => this.catchAuthorizationErrors(err));
  }

  /**
   * Used to make get requests before a user is officially logged in. By providing the username and user token, authentication
   * headers can be made for any endpoint that requires authentication
   */
  getForUser(
    path: string,
    username: string,
    token: string,
    headers?: AuthRequestHeaders,
    responseType = 'json'
  ): Observable<any> {
    const url = this.getUrl(path);
    const contentType = headers?.[Headers.CONTENT_TYPE] || 'application/json';
    const accept = headers?.[Headers.ACCEPT];
    headers = generateRequestHeaders({
      url,
      accept,
      username,
      key: token,
      method: Http.GET,
      contentType: contentType ? (contentType === 'multipart/form-data' ? null : contentType) : 'application/json',
      xDate: generateXDate(),
    });

    return ajax({ method: Http.GET, url, responseType, headers })
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .catch(err => this.catchAuthorizationErrors(err));
  }

  patchForUser(
    path: string,
    body: any,
    username: string,
    token: string,
    headers?: AuthRequestHeaders,
    responseType = 'json'
  ): Observable<any> {
    const url = this.getUrl(path);
    const contentType = headers?.[Headers.CONTENT_TYPE] || 'application/json';
    const accept = headers?.[Headers.ACCEPT];
    headers = generateRequestHeaders({
      url,
      accept,
      username,
      key: token,
      method: Http.PATCH,
      contentType: contentType ? (contentType === 'multipart/form-data' ? null : contentType) : 'application/json',
      xDate: generateXDate(),
    });

    return ajax({ method: Http.PATCH, url, responseType, body, headers })
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .catch(err => this.catchAuthorizationErrors(err));
  }

  protected catchAuthorizationErrors(error: AjaxError): ErrorObservable<any> {
    if (error.status === 401) {
      this.store.dispatch(logout());
    }

    return Observable.throw(error);
  }

  protected updateRequestHeaders(method: string, url: string, headers?: AuthRequestHeaders): AuthRequestHeaders {
    const tokens = JSON.parse(localStorage.getItem('okta-token-storage'));
    const xBearerAuthorization = tokens?.accessToken?.accessToken
      ? { [Headers.X_BEARER_AUTHORIZATION]: `Bearer ${tokens?.accessToken?.accessToken}` }
      : {};

    if (!headers) {
      headers = {
        ...this.generateHeaders(method, url, 'application/json'),
        ...xBearerAuthorization,
      };
    } else {
      let contentType = headers[Headers.CONTENT_TYPE] || 'application/json';
      const accept = headers[Headers.ACCEPT];

      if (contentType === 'multipart/form-data') {
        contentType = null;
      }

      const newHeaders = this.generateHeaders(method, url, contentType, accept);

      headers = { ...headers, ...newHeaders };
    }
    return headers;
  }

  generateHeaders(method: string, url: string, contentType: string, accept?: string) {
    const authState = this.store.getState().auth;
    if (authState.isAuthenticated && authState.user.isAuthenticated) {
      return this.getAuthenticatedRequestHeaders(method, url, contentType, authState, accept);
    }
    // Uses generic auth headers, without user data.
    return generateRequestHeaders({
      url,
      method,
      accept,
      contentType,
      key: environment.apiKey,
      xDate: generateXDate(),
      username: '',
    });
  }

  private getAuthenticatedRequestHeaders(
    method: string,
    url: string,
    contentType: string,
    authState: AuthState,
    accept?: string
  ): AuthRequestHeaders {
    const user = authState.user;
    if (!user.isAuthenticated) {
      throw new Error('User must be authenticated to generate authenticated request headers.');
    }
    return generateRequestHeaders({
      url,
      method,
      contentType,
      xDate: generateXDate(),
      key: user.token,
      username: user.username,
      accept,
    });
  }
}

