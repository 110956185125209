import { Container } from 'typedi';
import { Action, AnyAction } from 'redux';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';

import * as a from '@features/security/auth.actions';
import { AuthRepository } from 'app/repositories/auth.repository';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { completeAppInitialization, initApp } from '../../../store/epics/app.actions';
import { loginFromCookie } from '@features/security/auth.actions';
import { initCulture } from 'app/i18n/culture.actions';
import { UserRepository } from 'app/repositories/user.repository';

type AuthEpic<OutputAction extends Action> = NavCarrierEpic<OutputAction, {
  auth: AuthRepository
  userRepo: UserRepository
}>;

export const initializeUserFromSessionStorage: AuthEpic<a.LoadUserDataSuccessAction | Action> = (action$, state$, {auth, userRepo}) =>
  action$.ofType(a.INIT_USER)
    .map(() => ({userJSON: auth.loadUserFromSession(), token: auth.loadUserTokenFromSession()}))
    .filter(({userJSON, token}) => Boolean(userJSON && token))
    .mergeMap(({userJSON}) =>
      userRepo.refreshUserInSession(userJSON.username, userJSON.hashedPassword)
        .catch(() => Observable.of(userJSON)) // Keep the old user JSON in case of random errors (like the user lost internet briefly)
    )
    .mergeMap(
      (userJSON) =>
        (userJSON.carrierCode?.startsWith('V') || userJSON.carrierCode === 'TMPCARRIER')
          ? ActionsObservable.of(completeAppInitialization(), a.verifyTermsAndConditionsStatus(userJSON, null))
          : ActionsObservable.of(a.fetchCarrierForUser(userJSON))
    );

export const initializeUserFromCookieEpic: AuthEpic<a.LoadUserDataSuccessAction | Action> = (action$, state$, {auth}) =>
  action$.ofType(a.INIT_USER)
    .map(() => ({userJSON: auth.loadUserFromSession(), token: auth.loadUserTokenFromSession()}))
    .filter(({userJSON, token}) => !Boolean(userJSON && token)) // don't log in from cookie when the user is already logged in.
    .filter(() => auth.hasRemoteLoginCookie())
    .mapTo(loginFromCookie());

export const initializeAppWithNoUserEpic: AuthEpic<a.LoadUserDataSuccessAction | Action> = (action$, state$, {auth}) =>
  action$.ofType(a.INIT_USER)
    .map(() => ({userJSON: auth.loadUserFromSession(), token: auth.loadUserTokenFromSession()}))
    .filter(({userJSON, token}) => !Boolean(userJSON && token))
    .filter(() =>  !auth.hasRemoteLoginCookie())
    .mergeMap(() => ActionsObservable.from([completeAppInitialization(), initCulture()]));

export const storeUserInSessionEpic: AuthEpic<never> = (action$, state$, {auth}) =>
  action$.ofType<a.CompleteLoginAction>(a.COMPLETE_LOGIN)
    .do(({userJSON}) => auth.storeUserInSession(userJSON))
    .ignoreElements();

export const clearUserFromSessionEpic: AuthEpic<AnyAction> = (action$, state$, {auth}) =>
  action$.ofType(a.LOGOUT)
    .do(auth.clearUserFromSession)
    .map(initApp); // DO NOT re-initialize app until we are sure user has been cleared from session

export const userSessionEpics = (action$, state$, {auth}) => combineEpics(
  clearUserFromSessionEpic,
  storeUserInSessionEpic,
  initializeUserFromSessionStorage,
  initializeUserFromCookieEpic,
  initializeAppWithNoUserEpic,
)(action$, state$, {auth, userRepo: Container.get(UserRepository)});
