import moment from 'moment';

import { BaseModel } from 'shared/models/base.model';
import { CarrierInsurance, InsuranceStatus } from 'shared/models/carrier/carrier-insurance.model';
import { CarrierCertificate, CarrierCertification } from 'shared/models/carrier/carrier-certification.model';
import { CertificationType, CertificationStatus } from 'shared/enums/enums';

export class CarrierDetail extends BaseModel<CarrierDetailJSON> implements CarrierDetailInterface {
  readonly name: string;
  readonly carrierCode: string;
  readonly capCode: string;
  readonly carrierQualificationStatus: string;
  readonly isInsuranceRequired: boolean;
  readonly isSac: boolean;
  readonly physicalAddress: PhysicalAddress;
  readonly carrierInsurance: CarrierInsurance[] = [];
  readonly carrierCertifications: CarrierCertification[] = [];
  readonly communicationPreferences: {
    [name: string]: string;
  };
  readonly carrierSize: string;
  private _hazmatCert: CarrierCertification;

  constructor(json?: CarrierDetailJSON) {
    super(json);

    if (json) {
      this.carrierInsurance = (json.carrierInsurance) ? json.carrierInsurance.map(item => new CarrierInsurance(item)) : [];
      this.carrierCertifications = json.carrierCertifications ? json.carrierCertifications.map(item => new CarrierCertification(item)) : [];
    }
  }

  toJson(): CarrierDetailJSON {
    const response: CarrierDetailJSON = Object.assign({}, this as any);

    response.carrierInsurance = this.carrierInsurance.map(insurance => insurance.toJson());

    return response;
  }

  public hasExpiredInsurance(): boolean {
    return this.carrierInsurance.reduce((result, insurance) => result || insurance.insuranceStatus === InsuranceStatus.Expired, false);
  }

  public hasExpiringInsurance(): boolean {
    return this.carrierInsurance.reduce((result, insurance) => result || insurance.insuranceStatus === InsuranceStatus.ExpiresSoon, false);
  }

  get daysUntilInsuranceExpiration() {
    return this.carrierInsurance
      .map(insurance => insurance.daysUntilExpiration)
      .reduce((currentSoonest, days) => Math.min(currentSoonest, days), 10000);
  }

  private get hazmatCertification(): CarrierCertification {
    if (!this._hazmatCert) {
      this._hazmatCert = this.carrierCertifications.find(cert => cert.type === CertificationType.Hazmat);
    }
    return this._hazmatCert;
  }

  private get hazmatCertificate(): CarrierCertificate {
    return this.hazmatCertification?.certification;
  }

  public get isCarrierCodeOdd(): boolean {
    return Boolean(parseInt(this.carrierCode.trim().slice(-1), 10) % 2 !== 0);
  }

  public get isHazmatRequired(): boolean {
    return this.hasHazmatCertification();
  }

  public hasHazmatCertification(): boolean {
    return Boolean(this.hazmatCertificate);
  }

  public hasExpiredHazmat(): boolean {
    return Boolean(this.hazmatCertificate)
      && (this.hazmatCertificate.status === CertificationStatus.Expired)
      && this.daysSinceHazmatExpiration != null
      && this.daysSinceHazmatExpiration < 14;
  }

  public hasValidHazmatCertification(): boolean {
    return Boolean(this.hazmatCertificate)
      && this.hazmatCertificate.status !== CertificationStatus.Expired;
  }

  public hasExpiringHazmat(): boolean {
    return Boolean(this.hazmatCertificate)
      && this.daysUntilHazmatExpiration != null
      && this.daysUntilHazmatExpiration <= 15
      && this.hazmatCertificate.status === CertificationStatus.ExpiresSoon;
  }

  get daysUntilHazmatExpiration(): number {
    if (!this.hasHazmatCertification()) {
      return null;
    }
    const today = moment().startOf('day');
    const expiration = moment(this.hazmatCertificate.expirationDateTime).startOf('day');

    return expiration.diff(today, 'days');
  }

  get daysSinceHazmatExpiration(): number {
    if (!this.hasHazmatCertification() || this.hazmatCertificate.expirationDateTime == null) {
      return null;
    }
    const today = moment().startOf('day');
    const expiration = moment(this.hazmatCertificate.expirationDateTime).startOf('day');

    return today.diff(expiration, 'days');
  }

  get typeOfInsurance(): number {
    return this.carrierInsurance
      .map(insurance => insurance.typeOfInsurance)
      .reduce((currentSoonest, days) => Math.min(currentSoonest, days));
  }
}
