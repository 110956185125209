export const messagesToSuppress = [
	// suppresses SignalR connectivity issue
	'Failed to complete negotiation with the server:',
	// suppresses azure maps errors
	'touchZoomRotate.onStart is not a function',
	'Failed to retrieve the style definitions: TypeError: Failed to fetch',
	// suppress console errors from ad blockers
	'UET is not defined',
	'Munchkin is not defined',
	'Prettify fetch failed'
];

export const urlsExcludedFromLoadingTimes = [
	'launchdarkly.com',
	'adobedtm.com',
	'adsrvr.org',
	'app.link',
	'bing.com',
	'comm100.com',
	'comm100vue.com',
	'demdex.net',
	'doubleclick.net',
	'everesttech.net',
	'facebook',
	'findmanualsonline.net',
	'fullstory.com',
	'google',
	'googleapis.com',
	'googletagmanager.com',
	'gstatic.com',
	'honey.io',
	'kampyle.com',
	'kaspersky - labs.com',
	'marketo.net',
	'microsoft.com',
	'mktoresp.com',
	'mktoutil.com',
	'omtrdc.net',
	'ringcentral.com'
]