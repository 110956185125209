import { AnyAction, applyMiddleware, createStore } from 'redux';
import { routerMiddleware, RouterState } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
import { Container, Token } from 'typedi';
import { createBrowserHistory } from 'history';

import { User } from 'shared/models/user.model';
import { SignalrMiddleware, LoggerMiddleware } from './middleware';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { ToastManager } from 'shared/components/toast/toast.actions';
import { Invoice } from 'shared/models/invoice/invoice.model';
import { ResultSet } from 'shared/models/result-set.model';
import { Configuration } from 'shared/models/config/configuration.model';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { createReducerManager } from 'store/reducer-manager';

import { rootEpic } from 'store/epics/root.epics';
import { rootReducer } from 'store/root.reducers';

const history = createBrowserHistory({ getUserConfirmation: () => {} });
export const History = new Token<typeof history>();
Container.set(History, history);

export const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, NavCarrierState>();
export const reducerManager = createReducerManager(rootReducer(history));

const composedEnhancers = composeWithDevTools(applyMiddleware(
  thunk,
  LoggerMiddleware,
  epicMiddleware,
  routerMiddleware(history),
  SignalrMiddleware
));

const store = createStore<NavCarrierState, AnyAction, { reducerManager?: typeof reducerManager }, {}>(
  reducerManager.reduce as any,
  composedEnhancers as any
);

store.reducerManager = reducerManager;

epicMiddleware.run(rootEpic);

export const Store = new Token<typeof store>();
Container.set(Store, store);

Container.set(ToastManager, new ToastManager(store.dispatch));
export { history, store };

export interface AppState {
  defaultCountry: CountryResponse;
  initialized: boolean;
  configuration: Configuration;
  isDetailView: number;
}

export interface RefDataState {
  equipmentTypes: ReferenceDataJSON[];
  specializedEquipmentTypes: ReferenceDataJSON[];
  extendedEquipmentTypes: ReferenceDataJSON[];
  loadFilters: ReferenceDataJSON[];
  location: {
    countries: CountryResponse[];
    states: { [key: number]: StateResponse[] }
  };
}

export interface AuthState {
  pending: {
    carrier?: CarrierDetail;
    userJSON?: UserJSON;
  };
  user: User;
  okta?: OktaState;
  isACSUser: boolean;
  carrier: CarrierDetail;
  token: string;
  isAuthenticated: boolean;
  errors: APIErrorResponse;
  postLoginActionQueue: AnyAction[];
  insuranceStatusConfirmed: boolean;
  insuranceStatusRequiresConfirmation: boolean;
}

export interface ManageInvoicesState {
  search: ManageInvoicesSearchCriteria;
  sorting: SortableRequest;
  pagination: PageableRequest;
  results: ResultSet<Invoice>;
  error: string;
}
