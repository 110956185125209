export enum AppRoute {
  ROOT = '/',
  HOME = '/home',
  LOGIN = '/login',
  TEST = '/test',
  LOGIN_V2 = '/login-v2',
  EMAIL_CONFIRMATION = '/login/email-confirmation',
  FORGOT_USERNAME = '/login/forgot-username',
  FORGOT_USERNAME_SUCCESS = '/login/forgot-username/success',
  FORGOT_PASSWORD = '/login/forgot-password',
  FORGOT_PASSWORD_SUCCESS = '/login/forgot-password/success',
  RESET_PASSWORD = '/login/reset-password',
  REGISTRATION = '/registration',
  REGISTRATION_SUCCESS = '/registration/success',
  FIND_LOADS_BASE = '/find-loads',
  FIND_LOADS = '/find-loads/:searchType',
  FIND_LOAD_DETAILS = '/find-load-details/:id',
  POST_TRUCKS = '/post-trucks',
  MY_LOADS = '/my-loads',
  MY_SHIPMENTS = '/my-shipments',
  MY_LOAD_DETAILS = '/load-details/:id',
  MY_SHIPMENT_DETAILS = '/shipment-details/:id',
  TENDERS = '/tenders',
  TENDERS_DEEP_LINK = '/tenders/:id/:sequenceNumber',
  SPOT_BIDS = '/spot-bids',
  SPOT_BID_DETAILS = '/spot-bid-details/:id/:carrierCode',
  ACCOUNTS_RECEIVABLE_DETAILS = '/accounts-receivable-details/:id',
  ACCOUNTS_RECEIVABLE_INVOICE_DETAILS = '/accounts-receivable-invoice-details/:id/:voucherId?/:voucherDocId?',
  ACCOUNTS_RECEIVABLE_CHECK_DETAILS = '/accounts-receivable-check-details/:id',
  ACCOUNTS_RECEIVABLE = '/accounts-receivable',
  PAYMENTS = '/payments',
  RATES = '/rates',
  PREFERRED_LANES = '/preferred-lanes',
  ADMINISTER_USERS = '/administer-users',
  IMPERSONATE_USER = '/impersonate-user',
  ANNOUNCEMENTS = '/announcements',
  DOCK_MANAGER = '/dock-manager',
  TMC_REPORTS = '/tmc-reports',
  OFFERS = '/my-offers',
  CONTACT_US = '/contact-us',
  BIN = '/book-it-now',
  BIN_DEEP_LINK = '/book-it-now/:id',
  SETTINGS_DEEP_LINK = '/settings/:sectionId',
  SETTINGS = '/settings',
  CLAIMS = '/claims',
  MANAGE_INVOICES = '/manage-invoices',
  CREATE_INVOICE = '/manage-invoices/create-invoice',
  EDIT_INVOICE = '/manage-invoices/edit-invoice/:id',
  EDIT_INVOICE_BASE = '/manage-invoices/edit-invoice',
  VIEW_INVOICE = '/manage-invoices/view-invoice/:id',
  VIEW_INVOICE_BASE = '/manage-invoices/view-invoice',
  SHORT_REGISTRATION = '/short-registration',
  SHORT_REGISTRATION_SUCCESS = '/short-registration/success',
  SHORT_REGISTRATION_ERROR = '/short-registration/error',
  UNSUBSCRIBE = '/unsubscribe',
  UNSUBSCRIBE_REGISTRATION = '/registration-unsubscribe',
  UNSUBSCRIBE_REGISTRATION_ERROR = '/registration-unsubscribe-error',
  UNSUBSCRIBE_ERROR = '/unsubscribe-error',
  DASHBOARD = '/carrier-advantage/dashboard',
  CARRIER_ADVANTAGE_PROGRAM = '/carrier-advantage/carrier-advantage-program',
  CARRIER_ADVANTAGE_PROGRAM_DEEP_LINK = '/carrier-advantage/carrier-advantage-program/:id',
  CARRIER_ADVANTAGE_FAQ = '/carrier-advantage/faq',
  CARRIER_ADVANTAGE_FAQ_DEEP_LINK = '/carrier-advantage/faq/:id',
  SMS_SUBSCRIPTION = '/settings/sms',
  CAP_SCORE_DETAILS = '/carrier-advantage/score-details',
  CAP_SCORE_DETAILS_DEEP_LINK = '/carrier-advantage/score-details/:id',
  OKTA_FAQ = '/frequently-asked/okta',
  VERIFY = '/verify-preferred-email',
  VERIFY_EMAIL = '/verify-email-address',
  LOGIN_OTP = '/login-otp',
  RESOLVE_MODAL_BASE = '/tr',
  RESOLVE_MODAL = '/tr/:id',
  FINANCIAL_TERMS_AND_CONDITIONS = '/promo-rules/financial-terms-and-conditions',
  OKTA_SIGNIN_REDIRECT = '/login/signin-redirect',
  UNABLE_TO_SIGNIN_ON_WEB = '/unable-to-signin-on-web'
}

export enum StartPageRedirects {
  SETTINGS = AppRoute.SETTINGS,
  POST_TRUCKS = AppRoute.POST_TRUCKS,
  MY_LOADS = AppRoute.MY_LOADS,
  ACCOUNT_RECEIVABLE = AppRoute.ACCOUNTS_RECEIVABLE,
  PAYMENTS = AppRoute.PAYMENTS,
  FIND_LOADS = AppRoute.FIND_LOADS_BASE,
  TMC_TENDERS = AppRoute.TENDERS,
  CHRW_TENDERS = AppRoute.TENDERS,
  RATES = AppRoute.RATES,
  SPOT_BIDS = AppRoute.SPOT_BIDS,
  CARRIER_TASKS = AppRoute.MY_LOADS, // Redirect to My Loads
  MANAGE_INVOICES = AppRoute.MANAGE_INVOICES,
  PREFERRED_LANES = AppRoute.PREFERRED_LANES,
  EMAIL_PROFILE = AppRoute.PREFERRED_LANES, // Redirect to preferred lanes
  BIN = AppRoute.FIND_LOADS_BASE,
  TMC_REPORTS = null, // TODO TMC_REPORTS should not be allowed to be a user's start page, as it is an external page.
  FREIGHT_ALERTS = null, // IGNORE
  ADMINISTER_USERS = null,
  IMPERSONATE = null,
  DOCK_MGR = null,
  MY_SHIPMENTS = AppRoute.MY_SHIPMENTS,
}

export const StartPageSort = {
  FIND_LOADS: 0,
  PREFERRED_LANES: 1,
  OFFERS: 1,
  POST_TRUCKS: 2,
  MY_LOADS: 3,
  SPOT_BIDS: 4,
  CHRW_TENDERS: 5,
  TMC_TENDERS: 5,
  DOCK_MGR: 6,
  RATES: 6,
  TMC_REPORTS: 7,
  ACCOUNT_RECEIVABLE: 7,
  PAYMENTS: 7,
  MANAGE_INVOICES: 7,
  CARRIER_TASKS: 7,
  EMAIL_PROFILE: 9,
  ADMINISTER_USERS: 9,
  IMPERSONATE: 9,
  FREIGHT_ALERTS: 9,
};
